import React from 'react';

const NotFoundPage = () => (
  <div id="main" className="alt">
    <section id="one" style={{ textAlign: 'center' }}>
      <div className="inner">
        <h1>PAGE NOT FOUND</h1>
      </div>
    </section>
  </div>
);

export default NotFoundPage;
